import Vue from 'vue'
import App from './App.vue'
import qs from 'qs'
import router from './router'
import store from './store'
import Es6Promise from 'es6-promise'
import './const/bridge'
import 'babel-polyfill'
import { goTo, goRep, goBack, goResolve } from '@/const/common.js'
import {
  copySubmit,
  interceptFn,
  noTipFn,
  roundingFn,
  numberOnlyFn,
  interceptFn2,
  scientificToNumber
} from '@/util/filters'




Vue.prototype.goTo = goTo

Vue.prototype.copySubmit = copySubmit
Vue.prototype.interceptFn = interceptFn
Vue.prototype.noTipFn = noTipFn
Vue.prototype.roundingFn = roundingFn
Vue.prototype.numberOnlyFn = numberOnlyFn
Vue.prototype.interceptFn2 = interceptFn2
Vue.prototype.scientificToNumber = scientificToNumber

Vue.prototype.goRep = goRep
Vue.prototype.qs = qs
Vue.prototype.goBack = goBack
Vue.prototype.goResolve = goResolve
Vue.config.productionTip = false

require('es6-promise').polyfill()
Es6Promise.polyfill()


import Vconsole from 'vconsole'
 
let vConsole = new Vconsole()
 
Vue.use(vConsole);
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0

  document.documentElement.scrollTop = 0

  window.pageYOffset = 0

  next()
})

window.vm =new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
