<template>
  <div class="gather">
    <h2>前端调用原生</h2>
    <div class="item">
      <div>创建钱包 （ createWallet ）</div>
      <div>
        <div>
          <p>钱包名称</p>
          <input placeholder="钱包名称" type="text" v-model="walletName" />
        </div>
        <div>
          <p>盐值</p>
          <input placeholder="盐值" type="text" v-model="salt" />
        </div>
        <div>
          <p>钱包密码</p>
          <input placeholder="钱包密码" type="text" v-model="password" />
        </div>
        <div v-if="mnemoniwcords">助记词: {{ mnemoniwcords }}</div>

        <div class="btn" @click="createWalletFn">创建钱包</div>
      </div>
    </div>
    <div class="item">
      <div>恢复钱包 （ restoreWallet ）</div>
      <div>
        <div>
          <p>助记词</p>
          <input placeholder="助记词" type="text" v-model="restoremnemonics" />
        </div>
        <div>
          <p>钱包名称</p>
          <input
            placeholder="钱包名称"
            type="text"
            v-model="restorewalletName"
          />
        </div>
        <div>
          <p>盐值</p>
          <input placeholder="盐值" type="text" v-model="restoresalt" />
        </div>
        <div>
          <p>钱包密码</p>
          <input
            placeholder="钱包密码"
            type="text"
            v-model="restoremnemoniwcords"
          />
        </div>
        <div v-if="restorewalletAddress">
          钱包地址: {{ restorewalletAddress }}
        </div>

        <div class="btn" @click="restoreWalletFn">恢复钱包</div>
      </div>
    </div>
    <div class="item">
      <div>校验钱包 （ validateWallet ）</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input
            placeholder="钱包地址"
            type="text"
            v-model="validatewalletAddress"
          />
        </div>

        <div>
          <p>钱包密码</p>
          <input
            placeholder="钱包密码"
            type="text"
            v-model="validatepassword"
          />
        </div>
        <div v-if="validateWalletData">返回值: {{ validateWalletData }}</div>

        <div class="btn" @click="validateWalletFn">校验钱包</div>
      </div>
    </div>
    <div class="item">
      <div>连接钱包 （ walletConnectBridge ）</div>
      <div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project" />
        </div>
        <div>
          <p>当前域名</p>
          <input placeholder="当前域名" type="text" v-model="website" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="args" />
        </div>
        <div>
          <div>钱包地址：{{ walletAddress }}</div>
          <div>钱包名称：{{ walletName }}</div>
          <div @click="copySubmit(sign)">签名Sign：{{ sign }}</div>
          <div class="btn" @click="walletConnectBridgeFn">连接钱包</div>
        </div>
      </div>
    </div>
    <div class="item">
      <div>签名验证:(isSignValid)</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input placeholder="请输入" type="text" v-model="walletAddress1" />
        </div>
        <div>
          <p>签名内容</p>
          <textarea placeholder="签名内容" type="text" v-model="signData" />
        </div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="isSignValidArgs" />
        </div>
        <div>
          <div>验证结果：{{ flag }}</div>
          <div class="btn" @click="isSignValidFn">验证签名</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>V2连接钱包 （ walletConnectBridge2 ）</div>
      <div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project" />
        </div>
        <div>
          <p>当前域名</p>
          <input placeholder="当前域名" type="text" v-model="website" />
        </div>
        <div>
          <div>钱包地址：{{ walletAddress }}</div>
          <div>钱包名称：{{ walletName }}</div>
          <div class="btn" @click="walletConnectBridge2Fn">V2-连接钱包</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>V2签名 （ walletSignBridgeV2 ）</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input placeholder="请输入" type="text" v-model="walletAddress2" />
        </div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="projectSign2" />
        </div>
        <!-- <div>
          <p>当前域名</p>
          <input placeholder="当前域名" type="text" v-model="websiteSign2" />
        </div> -->
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="argsSign2" />
        </div>
        <div>
          <div @click="copySubmit(sign2)">签名Sign：{{ sign2 }}</div>
          <div class="btn" @click="walletSignBridgeV2Fn">V2-签名</div>
        </div>
      </div>
    </div>
    <div class="item">
      <div>V2签名验证:(isSignValid2)</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input placeholder="请输入" type="text" v-model="walletAddress" />
        </div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project" />
        </div>
        <div>
          <p>签名内容</p>
          <textarea placeholder="签名内容" type="text" v-model="signData2" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="isSignValidArgs2" />
        </div>
        <div>
          <div>验证结果：{{ flag2 }}</div>
          <div class="btn" @click="isSignValid2Fn">V2-验证签名</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>连接钱包V3 （ walletConnectBridgeV3 ）</div>
      <div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project3" />
        </div>
        <div>
          <p>当前域名</p>
          <input placeholder="当前域名" type="text" v-model="website3" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="args3" />
        </div>
        <div>
          <div>钱包地址：{{ walletAddress3 }}</div>
          <div>钱包名称：{{ walletName3 }}</div>
          <div @click="copySubmit(sign3)">签名Sign：{{ sign3 }}</div>
          <div>公钥：{{ publicKey3 }}</div>
          <div class="btn" @click="walletConnectBridgeV3Fn">连接钱包</div>
        </div>
      </div>
    </div>
    <div class="item">
      <div>签名验证V3:(isSignValid3Bridge)</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input placeholder="请输入" type="text" v-model="walletAddress3" />
        </div>
        <div>
          <p>签名内容</p>
          <textarea placeholder="签名内容" type="text" v-model="signData3" />
        </div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project3" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="isSignValidArgs3" />
        </div>
        <div>
          <div>验证结果：{{ flag3 }}</div>
          <div class="btn" @click="isSignValid3Fn">验证签名</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>连接钱包V4 （ walletConnectBridgeV4 ）</div>
      <div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project4" />
        </div>
        <div>
          <p>当前域名</p>
          <input placeholder="当前域名" type="text" v-model="website4" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="args4" />
        </div>
        <div>
          <div>钱包地址：{{ walletAddress4 }}</div>
          <div>钱包名称：{{ walletName4 }}</div>
          <div @click="copySubmit(sign3)">签名Sign：{{ sign4 }}</div>
          <div @click="copySubmit(publicKey4)">公钥：{{ publicKey4 }}</div>
          <div class="btn" @click="walletConnectBridgeV4Fn">连接钱包</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>签名验证V4:(isSignValid4Bridge)</div>
      <div>
        <div>
          <p>钱包地址</p>
          <input placeholder="请输入" type="text" v-model="walletAddress4" />
        </div>
        <div>
          <p>签名内容</p>
          <textarea placeholder="签名内容" type="text" v-model="signData4" />
        </div>
        <div>
          <p>网站名称</p>
          <input placeholder="网站名称" type="text" v-model="project4" />
        </div>
        <div>
          <p>args</p>
          <input placeholder="请输入" type="text" v-model="isSignValidArgs4" />
        </div>
        <div>
          <div>验证结果：{{ flag4 }}</div>
          <div class="btn" @click="isSignValid4Fn">验证签名</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div>授权转账 （ callSendBridge ）</div>
      <div>
        <div>
          <p>目标地址</p>
          <input placeholder="目标地址" type="text" v-model="toAddress" />
        </div>
        <div>
          <p>转账数量</p>
          <input placeholder="转账数量" type="number" v-model="amount" />
        </div>
        <div>
          <p>Token ID</p>
          <input placeholder="Token ID" type="text" v-model="tokenId" />
        </div>
        <div>
          <p>Token Name</p>
          <input placeholder="Token Name" type="text" v-model="tokenName" />
        </div>
        <div>
          <p>Chain Name</p>
          <input placeholder="Chain Name" type="text" v-model="chainName" />
        </div>

        <div class="btn" @click="callSendBridgeFn">授权转账</div>
      </div>
    </div>
    <div class="item">
      <div>拨打电话 （ callPhoneBridge ）</div>
      <div>
        <div>
          <p>电话号码</p>
          <input placeholder="电话号码" type="number" v-model="phoneNumber" />
        </div>
        <div class="btn" @click="callPhoneBridgeFn">拨打电话</div>
      </div>
    </div>
    <div class="item">
      <div>系统打开网页 （ browserOpenBridge ）</div>
      <div>
        <div>
          <p>网页链接</p>
          <input placeholder="请输入网页链接" type="text" v-model="url" />
        </div>
        <div class="btn" @click="browserOpenBridgeFn">打开网页</div>
      </div>
    </div>
    <div class="item">
      <div>系统打开邮件 （ mailOpenBridge ）</div>
      <div>
        <div>
          <p>邮件</p>
          <input placeholder="请输入邮件" type="text" v-model="email" />
        </div>
        <div class="btn" @click="mailOpenBridgeFn">打开邮件</div>
      </div>
    </div>
    <br />
    <br />
    <h2>原生调用前端</h2>
    <div class="item">
      <div>获得app环境的语言 （ _getLangBridge ）</div>
      <div>
        <div>
          <p>当前语言：{{ lang || "-" }}</p>
        </div>
        <div class="btn" @click="_getLangBridgeFn">获得当前语言</div>
      </div>
    </div>
    <div class="item">
      <div>
        监听是否切换了钱包并获取最新的钱包
        <p>（ _switchWalletBridge ）</p>
      </div>
      <div>
        <div>
          <p>当前钱包：{{ address || "-" }}</p>
        </div>
        <div class="btn" @click="_switchWalletBridgeFn">获取</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 创建钱包
      walletName: null,
      salt: null,
      password: null,
      mnemoniwcords: null,
      sign: null,
      project: "demo",
      website: window.location.origin,

      // 恢复钱包
      restoremnemonics: null,
      restoresalt: null,
      restorewalletName: null,
      restorepassword: null,
      restoremnemoniwcords: null,
      restorewalletAddress: null,

      // 校验钱包
      validatewalletAddress: null,
      validatepassword: null,
      validateWalletData: null,

      // 连接钱包
      walletAddress: null,
      walletAddress1: null,
      walletName: null,
      flag: null,
      args: null,
      signData: null,
      isSignValidArgs: null,

      // 连接钱包2
      flag2: null,
      args2: null,
      sign2: null,
      signData2: null,
      isSignValidArgs2: null,
      walletAddress2: null,

      // V2签名
      projectSign2: "demo",
      websiteSign2: window.location.origin,
      argsSign2: null,

      // 授权转账
      toAddress: null,
      amount: null,
      tokenId: null,
      tokenName: null,
      chainName: null,
      // 拨打电话
      phoneNumber: null,
      // 系统打开网页
      url: "https://www.google.com/",
      // 系统打开邮件
      email: null,
      // 当前语言
      lang: null,
      address: null,

      // V3
      flag3: null,
      isSignValidArgs3: null,
      project3: null,
      signData3: null,
      walletAddress3: null,
      sign3: null,
      walletName3: null,
      args3: null,
      website3: window.location.origin,
      project3: "demo",

      publicKey3: null,

      // V4
      flag4: null,
      isSignValidArgs4: null,
      project4: null,
      signData4: null,
      walletAddress4: null,
      sign4: null,
      walletName4: null,
      args4: null,
      website4: window.location.origin,
      project4: "demo",
      publicKey4: null,
    };
  },
  methods: {
    // 创建钱包
    createWalletFn() {
      var self = this;
      try {
        _JCA._getMethod(
          {
            callBack: "createWallet",
            walletName: self.walletName,
            salt: self.salt,
            password: self.password,
          },
          (msg) => {
            if (msg.data && msg.flag) {
              self.mnemoniwcords = msg.data;
            } else {
              alert(msg.message);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // 恢复钱包
    restoreWalletFn() {
      var self = this;
      try {
        _JCA._getMethod(
          {
            callBack: "restoreWallet",
            mnemonics: self.restoremnemonics,
            walletName: self.restorewalletName,
            salt: self.restoresalt,
            password: self.restorepassword,
          },
          (msg) => {
            if (msg.data && msg.flag) {
              self.restorewalletAddress = msg.data;
            } else {
              alert(msg.message);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // 校验钱包
    validateWalletFn() {
      var self = this;

      try {
        _JCA._getMethod(
          {
            callBack: "validateWallet",
            walletAddress: self.validatewalletAddress,
            password: self.validatepassword,
          },
          (msg) => {
            if (msg.data && msg.flag) {
              self.validateWalletData = msg.data;
            } else {
              alert(msg.message);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // 连接钱包
    walletConnectBridgeFn() {
      var self = this;
      if (!this.project) {
        alert("请输入网站名称");
        return;
      }
      if (!this.website) {
        alert("请输入当前域名");
        return;
      }
      if (this.website.indexOf("http") == -1) {
        alert("请输入正确的域名");
        return;
      }
      // if (!this.args) {
      //   alert("请输入args");
      //   return;
      // }

      try {
        _JCA._getMethod(
          {
            callBack: "walletConnectBridge",
            project: this.project,
            website: this.website,
            args: this.args,
          },
          (msg) => {
            console.log(msg);
            if (msg.walletAddress) {
              localStorage.setItem("demo_walletAddress", msg.walletAddress);
              self.walletAddress = msg.walletAddress;
            }
            if (msg.walletName) {
              self.walletName = msg.walletName;
            }
            if (msg.sign) {
              self.sign = msg.sign;
              localStorage.setItem("demo_sign", msg.sign);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // 验证签名
    isSignValidFn() {
      var self = this;
      if (!this.walletAddress1) {
        alert("请输入钱包地址");
        return;
      }
      if (!this.project) {
        alert("请输入网站名称");
        return;
      }

      if (!this.signData) {
        alert("请输入签名内容");
        return;
      }
      // if (!this.isSignValidArgs) {
      //   alert("请输入args");
      //   return;
      // }

      try {
        _JCA._getMethod(
          {
            callBack: "isSignValidBridge",
            args: self.isSignValidArgs,
            signData: self.signData,
            project: this.project,
            walletAddress: this.walletAddress1,
          },
          (msg) => {
            self.flag = msg.flag;
            console.log(msg);
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // 连接钱包2
    walletConnectBridge2Fn() {
      var self = this;
      if (!this.project) {
        alert("请输入网站名称");
        return;
      }
      if (!this.website) {
        alert("请输入当前域名");
        return;
      }
      if (this.website.indexOf("http") == -1) {
        alert("请输入正确的域名");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "walletConnectBridge2",
            project: this.project,
            website: this.website,
            args: this.args2,
          },
          (msg) => {
            console.log(msg);
            if (msg.walletAddress) {
              localStorage.setItem("demo_walletAddress", msg.walletAddress);
              self.walletAddress = msg.walletAddress;
            }
            if (msg.walletName) {
              self.walletName = msg.walletName;
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // V2-签名
    walletSignBridgeV2Fn() {
      var self = this;
      if (!this.walletAddress2) {
        alert("请输入钱包地址");
        return;
      }
      if (!this.projectSign2) {
        alert("请输入网站名称");
        return;
      }
      if (!this.websiteSign2) {
        alert("请输入当前域名");
        return;
      }
      if (this.websiteSign2.indexOf("http") == -1) {
        alert("请输入正确的域名");
        return;
      }
      if (!this.argsSign2) {
        alert("请输入args");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "walletSignBridge2",
            project: this.projectSign2,
            website: this.websiteSign2,
            args: this.argsSign2,
            walletAddress: this.walletAddress2,
          },
          (msg) => {
            console.log(msg);
            if (msg.sign) {
              self.sign2 = msg.sign;
              localStorage.setItem("demo_sign", msg.sign);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // V2验证签名
    isSignValid2Fn() {
      var self = this;
      if (!this.walletAddress) {
        alert("请输入钱包地址");
        return;
      }
      if (!this.project) {
        alert("请输入网站名称");
        return;
      }

      if (!this.signData2) {
        alert("请输入签名内容");
        return;
      }
      if (!this.isSignValidArgs2) {
        alert("请输入args");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "isSignValid2Bridge",
            args: self.isSignValidArgs2,
            signData: self.signData2,
            project: this.project,
            walletAddress: this.walletAddress,
          },
          (msg) => {
            self.flag2 = msg.flag;
            console.log(msg);
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // 连接钱包V3
    walletConnectBridgeV3Fn() {
      var self = this;
      if (!this.project3) {
        alert("请输入网站名称");
        return;
      }
      if (!this.website3) {
        alert("请输入当前域名");
        return;
      }
      if (this.website3.indexOf("http") == -1) {
        alert("请输入正确的域名");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "walletConnectBridge3",
            project: this.project3,
            website: this.website3,
            args: this.args3,
          },
          (msg) => {
            console.log(msg);
            if (msg.walletAddress) {
              localStorage.setItem("demo_walletAddress", msg.walletAddress);
              self.walletAddress3 = msg.walletAddress;
            }
            if (msg.walletName) {
              self.walletName3 = msg.walletName;
            }
            if (msg.sign) {
              self.sign3 = msg.sign;
              localStorage.setItem("demo_sign", msg.sign);
            }
            if (msg.publicKey) {
              self.publicKey3 = msg.publicKey;
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    // 验证签名V3
    isSignValid3Fn() {
      var self = this;
      if (!this.walletAddress3) {
        alert("请输入钱包地址");
        return;
      }
      if (!this.project3) {
        alert("请输入网站名称");
        return;
      }

      if (!this.signData3) {
        alert("请输入签名内容");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "isSignValid3Bridge",
            args: self.isSignValidArgs3,
            signData: self.signData3,
            project: this.project3,
            walletAddress: this.walletAddress3,
          },
          (msg) => {
            self.flag3 = msg.flag;
            console.log(msg);
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // 连接钱包V4
    walletConnectBridgeV4Fn() {
      var self = this;
      if (!this.project4) {
        alert("请输入网站名称");
        return;
      }
      if (!this.website4) {
        alert("请输入当前域名");
        return;
      }
      if (this.website4.indexOf("http") == -1) {
        alert("请输入正确的域名");
        return;
      }

      try {
        _JCA._getMethod(
          {
            callBack: "walletConnectBridge4",
            project: this.project4,
            website: this.website4,
            args: this.args4,
          },
          (msg) => {
            console.log(msg);
            if (msg.walletAddress) {
              localStorage.setItem("demo_walletAddress", msg.walletAddress);
              self.walletAddress4 = msg.walletAddress;
            }
            if (msg.walletName) {
              self.walletName4 = msg.walletName;
            }
            if (msg.sign) {
              self.sign4 = msg.sign;
              localStorage.setItem("demo_sign", msg.sign);
            }
            if (msg.publicKey) {
              self.publicKey4 = msg.publicKey;
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // 验证签名V4
    isSignValid4Fn() {
      var self = this;
      if (!this.walletAddress4) {
        alert("请输入钱包地址");
        return;
      }
      if (!this.project4) {
        alert("请输入网站名称");
        return;
      }

      if (!this.signData4) {
        alert("请输入签名内容");
        return;
      }
    

      try {
        _JCA._getMethod(
          {
            callBack: "isSignValid4Bridge",
            args: self.isSignValidArgs4,
            signData: self.signData4,
            project: this.project4,
            publicKey: this.publicKey4,
          },
          (msg) => {
            self.flag4 = msg.flag;
            console.log(msg);
          }
        );
      } catch (e) {
        console.log(e);
      }
    },

    // 授权转账
    callSendBridgeFn() {
      var self = this;
      var obj = {
        instanceType: "Token",
        eventType: "TOKEN_TRANSFER",
        referrerInstance: 0,
        referrerTx: "",
        crossToInstance: 0,
        txMark: { clientName: "", osName: "" },
        data: {
          tokenId: self.tokenId,
          tokenName: self.tokenName,
          chainName: self.chainName,
          value: self.amount * Math.pow(10, 9),
          event: "",
        },
      };
      _JCA._getMethod(
        {
          callBack: "callSendBridge",
          amount: self.amount,
          toAddress: self.toAddress,
          txContext: JSON.stringify(obj),
        },
        (res) => {
          self.loadingBtn = false;
          if (res.code == -1) {
            return;
          }
          if (res.flag) {
            self.amount = "";
            Swal.fire({
              title: window.vm.$t("withdraw.reminder"),
              text: window.vm.$t(
                "withdraw.congratulations_the_transaction_was_successfully_sent"
              ),
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#8CC152",
              confirmButtonText: window.vm.$t("withdraw.copy_hash"),
              cancelButtonColor: "#d33",
              cancelButtonText: window.vm.$t("withdraw.close"),
            }).then((result) => {
              if (result.isConfirmed) {
                self.copySubmit(res.txHash);
              }
            });
          } else {
            Swal.fire({
              title: res.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#d33",
              confirmButtonText: window.vm.$t("withdraw.close"),
            }).then((result) => {
              if (result.isConfirmed) {
              }
            });
          }
        }
      );
    },
    // 拨打电话
    callPhoneBridgeFn() {
      _JCA._getMethod(
        {
          callBack: "callPhoneBridge",
          phoneNumber: this.phoneNumber,
        },
        (res) => {}
      );
    },
    // 系统打开网页
    browserOpenBridgeFn() {
      _JCA._getMethod(
        {
          callBack: "browserOpenBridge",
          url: this.url,
        },
        (res) => {}
      );
    },
    // 系统打开邮件
    mailOpenBridgeFn() {
      _JCA._getMethod(
        {
          callBack: "mailOpenBridge",
          email: this.email,
        },
        (res) => {}
      );
    },
    // 获取当前语言
    _getLangBridgeFn() {
      if (localStorage.getItem("demo_language") == "en_US") {
        this.lang = "英文";
      }
      if (localStorage.getItem("demo_language") == "zh_CN") {
        this.lang = "中文";
      }
    },
    // 开始监听是否切换了钱包
    _switchWalletBridgeFn() {
      this.address = localStorage.getItem("demo_walletAddress");
    },
  },
};
</script>

<style lang="less" scoped>
.gather {
  background: #fff;
  padding: 15px;
  .item {
    border: 1px solid #999;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
    p {
      margin: 0 !important;
      color: #333;
    }
    input {
      border: 1px solid #999;
      width: 100%;
      padding-left: 10px;
      height: 40px;
      color: #333;
    }
    > div:nth-child(1) {
      font-size: 16px;
      color: #333;
      border-bottom: 1px solid #999;
      padding-bottom: 10px;
    }
    > div:nth-child(2) {
      margin: 20px 0 0;

      > div {
        margin-top: 20px;
        color: #333;
        div {
          word-wrap: break-word;
        }
      }
    }
    .btn {
      background: #0d6efd;
      margin-top: 30px !important;

      width: 100%;
      color: #fff !important;
    }
  }
}
textarea {
  width: 100%;
  height: 200px;
  padding: 5px 10px;
}
</style>
