<template>
  <div id="page">
    <router-view />
    <div></div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      seconds: 0,
    
    };
  },
  mounted() {
   
 

  },
  methods: {},
};
</script>

<style lang="less">
</style>
